import { FC, ReactElement, useEffect } from "react";
import PageHero from "../../../../components/PageHero";
import Reference from "../../../../components/Reference";
import SharedProps from "../../../../models/SharedProps";

interface DiagnosticsProps extends SharedProps {};

const Diagnostics: FC<DiagnosticsProps> = (props: DiagnosticsProps): ReactElement => {
  const { translate, locale } = props;

  const t = (key: string): string => translate("diagnostics." + key);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = t("document_title");
  }, []);

  return (
    <>
      <PageHero
        title={t("hero.title")}
        imageUrl="https://evbiome-assets.s3.eu-central-1.amazonaws.com/hero__diagnostics.jpg"
//        imageUrl="https://thumbs.dreamstime.com/z/laboratory-experiment-science-research-lab-9051500.jpg"
//        imageUrl="https://image.shutterstock.com/image-photo/medical-scientist-conducting-research-blood-600w-761873668.jpg"
      >
{/*        <>
          <a onClick={() => document.getElementById("oncology")?.scrollIntoView({behavior: "smooth", block: "start"})}>
            <h3 className="page-hero__subsection-nav">{t("oncology.label")}</h3>
          </a>
          <a onClick={() => document.getElementById("neurodegenerative_diseases")?.scrollIntoView({behavior: "smooth", block: "start",})}>
            <h3 className="page-hero__subsection-nav">{t("neurodegenerative_diseases.label")}</h3>
          </a>
          <a onClick={() => document.getElementById("infectious_diseases")?.scrollIntoView({behavior: "smooth", block: "start"})}>
            <h3 className="page-hero__subsection-section-nav">{t("infectious_diseases.label")}</h3>
          </a>
        </> */}
      </PageHero>

      <div id="oncology" className="team__intro-container">
        <div className="team__intro app-margin">
          <div className="team__intro-text">
            <h3 style={{ marginTop: "2rem"}}>{t("oncology.label")}</h3>
            <h4 style={{ marginTop: "2rem", marginBottom: "2rem"}}>{t("oncology.text_1")}</h4>
            <div className="team__intro-image" style={{ width: "80%", maxWidth: "640px", margin: "auto" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <img
                  style={{ width: "48%" }}
                  src="https://evbiome-assets.s3.eu-central-1.amazonaws.com/diagnostics_oncology_1a.png"
                  alt="diagnostics-protocol"
                />
                <img
                  style={{ width: "48%" }}
                  src="https://evbiome-assets.s3.eu-central-1.amazonaws.com/diagnostics_oncology_1b.png"
                  alt="diagnostics-protocol"
                />
              </div>
              <div style={{ width: "100%", margin: "auto", marginTop: "1rem", textAlign: "left" }}>
                <small>
                  Electron Microscopy image of particles isolated by NBI showing intact and polydisperse vesicles.
                </small>
              </div>
            </div>
            <h4 style={{ marginTop: "2rem", marginBottom: "2rem"}}>
For example, the combination of 2 luminal markers of vesicles has the potential to distinguish vesicles produced in early gastrointestinal cancer patients from the background of vesicles produced by blood cells in healthy donors.
            </h4>
            <div className="team__intro-image" style={{ width: "80%", maxWidth: "640px", margin: "auto" }}>
              <img
                style={{ width: "-webkit-fill-available" }}
                src="https://evbiome-assets.s3.eu-central-1.amazonaws.com/diagnostics_oncology_2.png"
                alt="diagnostics-protocol-applications"
              />
            </div>
            <h4 style={{ marginTop: "2rem"}}>{t("oncology.text_3")}</h4>
          </div>
        </div>
      </div>

      <div id="neurodegenerative_diseases" className="team__intro-container">
        <div className="team__intro app-margin">
          <div className="team__intro-text">
            <h3>{t("neurodegenerative_diseases.label")}</h3>
            <h4 style={{ marginTop: "2rem", marginBottom: "2rem" }}>{t("neurodegenerative_diseases.text_1")}</h4>
            <div className="team__intro-image" style={{ width: "80%", maxWidth: "640px", margin: "auto"}}>
              <img
                style={{ width: "-webkit-fill-available" }}
                src="https://evbiome-assets.s3.eu-central-1.amazonaws.com/diagnostics_neurodegenerative_fig1.png"
                alt="diagnostics-protocol"
              />
              <br/>
              <div style={{ width: "100%", margin: "auto", marginTop: "1rem", textAlign: "left" }}>
                <small>
               In a pilot project, α-Synuclein was detected in EVs derived from human plasma and CSF. Detected levels in plasma are 10x higher in EVs isolated with NBI as compared with ultracentrifugation (UC), thus offering opportunities to develop early diagnostics tools in Parkinson Disease.
                </small>
              </div>
            </div>
            <h4 style={{ marginTop: "2rem"}}>
EVbiome is currently collaborating with the University of Bologna (Italy) to adapt the RT-QuIC assay <Reference to="https://doi.org/10.1007/s00401-020-02160-8" /> on the detection of Parkinson Disease and Creutzfeldt-Jakob misfolded proteins on vesicles isolated with NBI.
            </h4>
          </div>
        </div>
      </div>

      <div id="infectious_diseases" className="team__intro-container">
        <div className="team__intro app-margin">
          <div className="team__intro-text">
            <h3>{t("infectious_diseases.label")}</h3>
            <h4 style={{ marginTop: "2rem", marginBottom: "2rem" }}>
NBI has the potential to support the diagnostics of infectious diseases caused by bacteria or viruses. In such diseases, the presence of the pathogen is usually detected either by circulating traces of nucleic acids, either by the consequences of the infection (e.g., antigens or symptoms), which does not permit distinguishing active ongoing infections from past infections or dead pathogens.
<br/>
<br/>
Bacteria are known to produce EV-like vesicles named Outer Membrane Vesicles (OMVs) <Reference to="https://doi.org/10.1093/femsle/fny135" />. For example, Borrelia burgdoferi, the bacteria responsible for the Lyme disease, produces OMVs that contain bacterial transcript. Molecular diagnosis of Lyme disease is known to be problematic.  Digital droplet PCR (ddPCR) on plasma to detect Borrelia burgdoferi has been reported with 58.5% sensitivity vs 24.4% specificity with two-tiered serology at clinical diagnosis <Reference to="https://doi.org/10.1371/journal.pone.0235372" />. The detection of bacterial transcripts in OMVs after proper isolation might improve the assay by improving the sensitivity and focusing on the detection of biologically active pathogens.
<br/>
<br/>
Our platform has also the potential to detect “live viruses” within vesicles. For example, we have shotgun-sequenced RNA from isolated vesicles of 8 oncology patients and incidentally detected that, in one only out of the 8 subjects, a high proportion of non-human reads mapped to the GB virus C (hepatitis G virus, or Pegivirus C), an asymptomatic virus known to infect about 2% of the general population.
            </h4>
            <div className="team__intro-image" style={{ width: "80%", maxWidth: "500px", margin: "auto" }}>
              <img
                style={{ width: "-webkit-fill-available" }}
                src="https://evbiome-assets.s3.eu-central-1.amazonaws.com/diagnostics_infectious_fig2.png"
                alt="diagnostics-protocol-applications"
              />
              <br />
              <div style={{ width: "100%", margin: "auto", marginTop: "1rem", textAlign: "left" }}>
                <small>
                  Fig. Legend: Mapping of 278 reads (out of 10’000 sampled non-human or unmapped reads) to the viral genome of GB virus C.
                </small>
              </div>
            </div>
            <h4 style={{ margin: "2rem 0"}}>
We are currently establishing collaborations with the Institute Pasteur in France and the Laboratory of National Health in Luxembourg to evaluate the potential of our technology in other infectious diseases such as COVID-19 or latent tuberculosis for example.
            </h4>
          </div>
        </div>
      </div>
    </>
  )
}

export default Diagnostics;
