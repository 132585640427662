import Route from "./models/Route";

import Contact from "./views/Contact";

import Home from "./views/Home";

import News from "./views/News";

import Company from "./views/About/components/Company";
import Team from "./views/About/components/Team";

import NBI from "./views/Technology/components/NBI";
import Publications from "./views/Technology/components/Publications";
import PointOfCare from "./views/Technology/components/PointOfCare";

import Diagnostics from "./views/Programs/components/Diagnostics";
import Therapeutics from "./views/Programs/components/Therapeutics";

import Academia from "./views/Parternships/components/Academia";
import PharmasAndBiotechs from "./views/Parternships/components/PharmasAndBiotechs";

export const MAIN_ROUTES = [
  {
    id: "about",
    name: "about.label",
    path: "/company",
    component: Company,
    subroutes: [
      {
        id: "company",
        name: "company.label",
        path: "/company",
        component: Company 
      },
      {
        id: "team",
        name: "team.label",
        path: "/team",
        component: Team
      },
    ]
  },
  {
    id: "programs",
    name: "programs.label",
    path: "/diagnostics",
    component: Diagnostics,
    subroutes: [
      {
        id: "diagnostics",
        name: "diagnostics.label",
        path: "/diagnostics",
        component: Diagnostics 
      },
      {
        id: "therapeutics",
        name: "therapeutics.label",
        path: "/therapeutics",
        component: Therapeutics 
      }
    ]
  },
  {
    id: "partnerships",
    name: "partnerships.label",
    path: "/pharmas-and-biotechs",
    component: PharmasAndBiotechs,
    subroutes: [
      {
        id: "pharmas-and-biotechs",
        name: "pharmas-and-biotechs.label",
        path: "/pharmas-and-biotechs",
        component: PharmasAndBiotechs
      },
      {
        id: "academia",
        name: "academia.label",
        path: "/academia",
        component: Academia
      }
    ]
  },
  {
    id: "technology",
    name: "technology.label",
    path: "/nbi",
    component: NBI,
    subroutes: [
      {
        id: "nbi",
        name: "nbi.label",
        path: "/nbi",
        component: NBI
      },
      {
        id: "publications",
        name: "publications.label",
        path: "/publications",
        component: Publications,
        isDisabled: true
      },
      {
        id: "point-of-care",
        name: "point-of-care.label",
        path: "/point-of-care",
        component: PointOfCare,
        isDisabled: true
      }
    ] 
  },
  {
    id: "news",
    name: "news.label",
    path: "/news",
    component: News,
    isDisabled: true
  },
  {
    id: "contact",
    name: "contact.label",
    path: "/contact",
    component: Contact
  }
];

const ROUTES: Route[] = [
  {
    id: "home",
    name: "home",
    path: "/",
    component: Home
  },
  ...MAIN_ROUTES
]

export default ROUTES;

