import { AnyAction } from "redux";
import { Locale } from "../../models/Translations";
import { SET_LOCALE } from "./constants";

/**
 * Dispatched to set the app language
 *
 * @param {Locale} locale The locale (en, fr)
 * @return {object} An action object with a type of SET_LOCALE passing the preferred locale
 */

export const setLocale = (locale: Locale): AnyAction => {
  return {
    type: SET_LOCALE,
    locale
  }
}
