import { FC, ReactElement, useEffect } from "react";
import PageHero from "../../../../components/PageHero";
import SharedProps from "../../../../models/SharedProps";

interface NBIProps extends SharedProps {};

const NBI: FC<NBIProps> = (props: NBIProps): ReactElement => {
  const { translate, locale } = props;

  const t = (key: string): string => translate("nbi." + key);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = t("document_title");
  }, []);

  return (
    <>
      <PageHero
        title={t("hero.title")}
        subtitle={t("hero.subtitle")}
        textVariant="dark"
        imageUrl="https://evbiome-assets.s3.eu-central-1.amazonaws.com/hero__nbi.jpg"
        //imageUrl="https://image.shutterstock.com/image-illustration/human-cells-blue-background-3d-600w-580814518.jpg"
        // imageUrl="https://image.shutterstock.com/image-illustration/lymphocyte-white-blood-cells-transparency-600w-2175075427.jpg"
      />

      <div className="team__intro-container">
        <div className="team__intro app-margin">
          <div className="team__intro-text">
            <h4 style={{ marginTop: "2rem"}}>{t("text_1")}</h4>
            <br/>
            <h4 style={{ marginBottom: "2rem"}}>{t("text_2")}</h4>
            <div className="team__intro-image" style={{ width: "80%", maxWidth: "760px", margin: "auto" }}>
              <img
                style={{ width: "-webkit-fill-available" }}
                src="https://www.aevabiotech.com/wp-content/uploads/2020/10/NBI-protocol_EN_new.jpg"
                alt="nbi-protocol"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NBI;
