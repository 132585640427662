import classNames from "classnames";
import { FC, ReactElement, useEffect, useState } from "react";
import { AnyAction, Dispatch } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import CompanyLogo from "../../assets/evbiome_logo--light.png";

import Route from "../../models/Route";
import SharedProps from "../../models/SharedProps";
import { Locale } from "../../models/Translations";

import { setLocale } from "../../redux/app/actions";
import { selectCurrentPath, selectLocale } from "../../redux/app/selectors";

import { MAIN_ROUTES } from "../../routes";

import history from "../../utils/history";

import "./styles.css";

const ROUTES = MAIN_ROUTES
  .filter(({ isDisabled }) => !isDisabled)
  .map(({ subroutes, ...rest }) => ({
    ...rest,
    subroutes: subroutes?.filter(({ isDisabled }) => !isDisabled)
  }));

interface AppHeaderProps extends SharedProps {
  currentPath: string;
  setLocale: (locale: Locale) => AnyAction;
  locale: Locale;
};

const mapStateToProps = createStructuredSelector({
  locale: selectLocale,
  currentPath: selectCurrentPath,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    setLocale: (locale: Locale): AnyAction => dispatch(setLocale(locale))
  };
};

const AppHeader: FC<AppHeaderProps> = (props: AppHeaderProps): ReactElement => {
  const { currentPath, locale, setLocale, translate } = props;

  const t = (key: string): string => translate("header.nav." + key);

  const [visible, setVisible] = useState<string | undefined>(undefined);

  const goTo = (e: any, route: Route): void => {
    e.stopPropagation();
    history.push(route.path);
  };

  const isActive = (route: Route): boolean => {
    return currentPath === route.path || (route.subroutes || []).map(el => el.path).includes(currentPath);
  };

  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    function handleResize() {
      setIsOpen(false);
    }

    window.addEventListener('resize', handleResize)
  }, []);

  return (
    <div id="app-header" className={classNames("app__header")}>
      <div className="app-header app-margin">
        <div className="app-header__logo-container" onClick={() => history.push("/")}>
          <img
            className="app-header__logo"
            src={CompanyLogo}
            height={80}
            width={208}
            alt="evbiome_logo"
          />
        </div>
        <div className="app-header__nav">
          <Tippy
            interactive={true}
            theme="light"
            offset={[0, 0]}
            arrow={false}
            placement="bottom"
            visible={isOpen}
            content={
              <div style={{ width: "240px" }}>
                <ul className="app-header__mobile-nav-list">
                  {ROUTES.map((route: Route) => (
                    <li
                      key={["nav", route.id].join("-")}
                      className="app-header__mobile-nav-item"
                      onClick={(e) => {
                        goTo(e, route)
                        setIsOpen(false);
                      }}
                    >
                      <span
                        className={classNames("mobile-nav-item__label", {
                          "mobile-nav-item__label--active": isActive(route) && !route.subroutes
                        })}
                      >
                        {t(route.name)}
                      </span>
                      {!!route.subroutes && (
                        <div className="app-header__mobile-subnav-list">
                          <ul>
                            {route.subroutes.map((subroute: Route) => (
                              <li
                                key={["subnav", subroute.id].join("-")}
                                className="app-header__mobile-subnav-item"
                                onClick={(e) => {
                                  goTo(e, subroute);
                                  setIsOpen(false);
                                }}
 
                              >
                                <span
                                  className={classNames("mobile-nav-item__label", {
                                    "mobile-nav-item__label--active": isActive(subroute)
                                  })}
                                >
                                  {t(subroute.name)}
                                </span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            }
          > 
            <button className="app-header__nav-button" onClick={() => setIsOpen(!isOpen)}>
              <i className="fa fa-bars fa-2x" />
            </button>
          </Tippy>
          <ul className="app-header__nav-list">
            {ROUTES.map((route: Route) => (
              <Tippy
                onShow={() => setVisible(route.id)}
                onHide={() => setVisible(undefined)}
                interactive={true}
                theme="light"
                placement="top-start"
                disabled={!route.subroutes}
                arrow={false}
                content={
                  <div style={{ width: "160px"}}>
                    <ul className="app-header__subnav-list">
                    {route.subroutes?.map((subroute: Route) => (
                      <li
                        className="app-header__subnav-item"
                        onClick={(e) => goTo(e, subroute)}
                        key={`nav-${subroute.id}`}
                      >
                        <p className={classNames("app-header__subnav-item-label", { "app-header__subnav-item-label--active": isActive(subroute)} )}>
                          {t(subroute.name)}
                        </p>
                      </li>
                    ))}
                    </ul>
                  </div>
                }
                key={["header-nav", route.id].join("-")}
              >
                <li
                  id={`nav-${route.id}`}
                  onClick={(e) => goTo(e, route)}
                  className={"app-header__nav-item"}
                >
                  <p
                    className={classNames("app-header__nav-item-label", {
                      "app-header__nav-item-label--with-menu": !!route.subroutes,
                      "app-header__nav-item-label--active": isActive(route),
                      "app-header__nav-item-label--underline": (route.id === visible) && !!route.subroutes
                    })}
                  >
                    {t(route.name)}
                  </p>
                </li>
              </Tippy>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );

}

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
