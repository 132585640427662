import produce from "immer";
import { AnyAction, Reducer } from "redux";
import { Locale } from "../../models/Translations";
import { SET_LOCALE } from "./constants";

export interface AppState {
  locale: Locale;
  statuses: {[statusKey: string]: any}
}

export const initialState: AppState = {
  locale: "en",
  statuses: {}
};

const appReducer: Reducer<AppState, AnyAction> = (
  state = initialState,
  action: AnyAction
): AppState =>
  produce(state, (draft) => {
    switch (action.type) {

      case SET_LOCALE:
        draft.locale = action.locale;
        break;
    }
  });

export default appReducer;
