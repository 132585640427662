export default {
  "en": {
    // HOME PAGE
    "home.document_title": "EV Biome | Home",
    "home.mission.label": "Mission",
    "home.mission.text": "Our mission is to convert the potential of extracellular vesicles into more accessible and effective diagnostic and treatment solutions to support patients and physicians.",
    "home.mission.button.label": "Meet our team",

    // HEADER
    "header.nav.about.label": "About",
    "header.nav.contact.label": "Contact",
    "header.nav.technology.label": "Technology",
    "header.nav.programs.label": "Programs",
    "header.nav.news.label": "News",
    "header.nav.company.label": "Company",
    "header.nav.team.label": "Team",
    "header.nav.nbi.label": "NBI",
    "header.nav.point-of-care.label": "Point of Care",
    "header.nav.publications.label": "Publications",
    "header.nav.diagnostics.label": "Diagnostics",
    "header.nav.therapeutics.label": "Therapeutics",
    "header.nav.clinical-trials.label": "Clinical Trials",
    "header.nav.partnerships.label": "Partnerships",
    "header.nav.pharmas-and-biotechs.label": "Pharmas & Biotechs",
    "header.nav.academia.label": "Academia",

    // FOOTER
    "footer.nav.about.label": "About",
    "footer.nav.contact.label": "Contact",
    "footer.nav.technology.label": "Technology",
    "footer.nav.programs.label": "Programs",
    "footer.nav.news.label": "News",
    "footer.nav.company.label": "Company",
    "footer.nav.team.label": "Team",
    "footer.nav.nbi.label": "NBI",
    "footer.nav.point-of-care.label": "Point of Care",
    "footer.nav.publications.label": "Publications",
    "footer.nav.diagnostics.label": "Diagnostics",
    "footer.nav.therapeutics.label": "Therapeutics",
    "footer.nav.clinical-trials.label": "Clinical Trials",
    "footer.nav.partnerships.label": "Partnerships",
    "footer.nav.pharmas-and-biotechs.label": "Pharmas & Biotechs",
    "footer.nav.academia.label": "Academia",

    "footer.address": "Find us",
    "footer.email": "Message us",
    "footer.socials": "Follow us ",

    // COMPANY
    "company.document_title": "EV Biome | Company",
    "company.hero.title": "The company",
    "company.hero.subtitle": "Vision & mission ",
    "company.vision": "We believe that our health could benefit significantly if we could monitor it more closely and more precisely so that people could focus more on prevention and early treatment rather than treating late stage diseases. A typical example is oncology, where only a few number of indications have a screening tool approved, but we believe also that many other therapeutic areas are concerned such as neurodegenerative diseases, metabolic and cardiovascular diseases, infectious diseases, etc.",
    "company.mission": "We are convinced that extracellular vesicles have the power to solve many issues to materialize our vision. Our mission is to mobilize cutting edge science and technologies to launch innovative clinical applications to address unmet medical needs and improve health. Besides, we are profoundly concerned by the sustainability of healthcare systems and we believe that extracellular vesicles will play a great role from this perspective by enabling solutions that will be more affordable, for instance as compared to next generation sequencing of circulating DNA.",
  
    // TEAM
    "team.document_title": "EV Biome | Team",
    "team.hero.title": "The team",
    "team.hero.subtitle": "Scientists, innovators, & entrepreneurs",
    "team.intro_text": "We are scientists, innovators, and entrepreneurs. We are passionate about the conversion of science to applications and obsessed by clinical utility and affordability.",
    "team.story.title": "Our story",
    "team.story.text_1": "Seasoned industry professionals frustrated by the slowness of processes and the organizational glass walls in pharmas",
    "team.values.title": "Our values",
    "team.values.text_1": "Integrity",
    "team.values.text_2": "Transparency / honesty",

    //NBI
    "nbi.document_title": "EV Biome | NBI",
    "nbi.hero.title": "NBI",
    "nbi.hero.subtitle": "Nickel Based Isolation",
    "nbi.text_1": "Extracellular vesicles (EVs) are membranous particles, abundantly released by the cells in the extracellular space and in the biological fluids. EVs contain nucleic acids, proteins and lipids and their molecular content mirrors the one of the cell of origin. They are therefore a valuable source of information and a faithful representation of the state of health of the releasing cells.",
    "nbi.text_2": "EVBiome is leveraging on AEVA Biotech’s proprietary EVs isolation technology to capture EVs from biological fluids in a rapid, reproducible, cost-effective and high-throughput way. The innovative method is called NBI (Nickel Based Isolation) and exploits beads functionalized with nickel cations to recover intact EVs, still carrying their original features and thus usable for molecular characterizations of their biological content.",

    // PUBLICATIONS
    "publications.document_title": "EV Biome | Publications",
    "publications.hero.title": "Publications",
    "publications.hero.subtitle": "Publications, references, & patents",
    "publications.publications.label": "Publications",
    "publications.references.label": "References",
    "publications.patents.label": "Patents",
 
    // NEWS 
    "news.document_title": "EV Biome | News",
    "news.hero.title": "News",
    "news.hero.subtitle": "Media articles & events calendar",
    "news.news.label": "News",
    "news.events.label": "Events", 

     // CONTACT
    "contact.document_title": "EV Biome | Contact",
    "contact.hero.title": "Contact",
    "contact.hero.subtitle": "Do not hesitate to contact us!",
    "contact.form.name.label": "Name",
    "contact.form.email.label": "Email",
    "contact.form.subject.label": "Subject",
    "contact.form.message.label": "Message",
    "contact.form.name.placeholder": "John Doe",
    "contact.form.email.placeholder": "johndoe@example.com",
    "contact.form.subject.placeholder": "I.e. investment, general, media",
    "contact.form.message.placeholder": "Your message goes here",
    "contact.form.button.label": "Send",

     // POINT OF CARE
    "point-of-care.document_title": "EV Biome | Point of Care",
    "point-of-care.hero.title": "Point of Care",
    "point-of-care.hero.subtitle": "Products and services delivered to patients at the time of care",
    "point-of-care.ongoing.label": "Ongoing developments",

    // DIAGNOSTICS
    "diagnostics.document_title": "EV Biome | Diagnostics",
    "diagnostics.hero.title": "Diagnostics",
    "diagnostics.hero.subtitle": "Oncology, neurodegenerative diseases & infectious diseases",
    "diagnostics.oncology.label": "Oncology",
    "diagnostics.neurodegenerative_diseases.label": "Neurodegenerative Diseases", 
    "diagnostics.infectious_diseases.label": "Infectious Diseases",
    "diagnostics.oncology.text_1": "The unique capacity of NBI to isolate intact and polydisperse vesicles enables the development of new approaches to detect early cancer or Minimal Residual Disease (MRD) biomarkers based on combinations of several markers.",
    "diagnostics.oncology.text_3": "In addition, thanks to its easy setup, quick readout time, low cost-of-goods, and high reproducibility, NBI can also revolutionize other biomarker assays initially developed on exosomes isolated with ultracentrifugation or other technologies. EVbiome is currently transferring its know-how to a Swiss start-up to improve its screening asset.",
    "diagnostics.neurodegenerative_diseases.text_1": "A sub-family of neurodegenerative diseases include conditions where damages to neurons are directly or indirectly caused by the massive production of aberrant and/or misfolded proteins. As a mechanism of protection, body cells are attempting to get rid of these malignant proteins by excreting them through exosomes. This is well documented in Cerebrospinal Fluid (CSF) but screening in CSF requires lumbar punctures and is therefore not translatable in clinical practice. Here again EVbiome technology has the potential to detect in blood early biomarkers of neurodegenerative diseases such as Parkinson Disease, Alzheimer Disease, or prion diseases.",

    // THERAPEUTICS 
    "therapeutics.document_title": "EV Biome | Therapeutics",
    "therapeutics.hero.title": "Therapeutics",
    "therapeutics.hero.subtitle": "EV as vehicles for natural/manufactured APIs",
    "therapeutics.text_1": "The other application of exosomes and more generally EVs pertains to their use as therapeutic agents, either for their inherent potential or as vehicle to the delivery of drug payloads or more recently miRNAs or SiRNAs (iExosomes).",
    "therapeutics.text_2": "The relatively (very) low immune clearance of exosomes, and their efficiency and good tolerability have been established on several mice studies. ‘Natural’ exosomes isolated from adult multipotent stem cells (Mesenchymal stem cells, MSC), have been tested to treat diseases ranging from stroke to Osteoarthritis, and pulmonary infection.",
    "therapeutics.text_3": "The confirmation that exosomal microRNAs effectively engage target mRNA and suppress gene expression in recipient cells has led to trials where the exosome content was enriched in a specific miRNA or small interfering RNA (siRNA). These modified exosomes carrying siRNA have been tested in several studies.",
    "therapeutics.text_4": "",
    "therapeutics.text_5": "",
    "therapeutics.text_6": "Other modifications of exosomes have been demonstrated, for example, membrane modification to enhance cell type specific targeting, allowing for a tissue specific delivery or their payload (siRNA, other active principle). A very large number of clinical trials (>100) are ongoing involving the use of Exosomes for a range of applications.",
    "therapeutics.text_7": "This new field will require a stable, reliable method of EV isolation and preparation to pass the requirements of clinical trials testing. The conservative nature and the scalability of the NBI exosome isolation method makes it ideal to use as a therapeutic vehicle, since the vesicles are perfectly preserved, in contrast with other isolation methods (e.g., ultracentrifucation).",

    // CLINICAL TRIALS
    "clinical-trials.document_title": "EV Biome | Clinical Trials",
    "clinical-trials.hero.title": "Clinical Trials",
    "clinical-trials.hero.subtitle": "Subtitle",
    "clinical-trials.intro_text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum nisi quis eleifend quam adipiscing vitae proin sagittis nisl. Habitant morbi tristique senectus et netus et. Varius duis at consectetur lorem donec massa sapien faucibus et. Cursus vitae congue mauris rhoncus aenean vel. Aliquet nec ullamcorper sit amet risus. Sollicitudin nibh sit amet commodo nulla facilisi nullam vehicula ipsum. Consectetur a erat nam at lectus urna duis convallis. Scelerisque eu ultrices vitae auctor eu augue ut lectus. Tempus quam pellentesque nec nam aliquam sem et tortor. Turpis cursus in hac habitasse. Ullamcorper sit amet risus nullam eget felis eget. Sapien pellentesque habitant morbi tristique senectus et netus et malesuada. Quis varius quam quisque id diam vel quam elementum. Quam vulputate dignissim suspendisse in est. At elementum eu facilisis sed odio.",

    // PHARMAS & BIOTECHS 
    "pharmas-and-biotechs.document_title": "EV Biome | Pharmas & Biotechs",
    "pharmas-and-biotechs.hero.title": "Pharmas & Biotechs",
    "pharmas-and-biotechs.hero.subtitle": "",
    "pharmas-and-biotechs.intro_text": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum nisi quis eleifend quam adipiscing vitae proin sagittis nisl. Habitant morbi tristique senectus et netus et. Varius duis at consectetur lorem donec massa sapien faucibus et. Cursus vitae congue mauris rhoncus aenean vel. Aliquet nec ullamcorper sit amet risus. Sollicitudin nibh sit amet commodo nulla facilisi nullam vehicula ipsum. Consectetur a erat nam at lectus urna duis convallis. Scelerisque eu ultrices vitae auctor eu augue ut lectus. Tempus quam pellentesque nec nam aliquam sem et tortor. Turpis cursus in hac habitasse. Ullamcorper sit amet risus nullam eget felis eget. Sapien pellentesque habitant morbi tristique senectus et netus et malesuada. Quis varius quam quisque id diam vel quam elementum. Quam vulputate dignissim suspendisse in est. At elementum eu facilisis sed odio.",
    "pharmas-and-biotechs.section_1.label": "Why extracellular vesicles?",
    "pharmas-and-biotechs.section_1.text_1": "Extracellular vesicles have a great potential for biomarker discovery as illustrated by the significant number of publications across many indications, including but not limited to oncology, neurodegenerative diseases, cardiovascular and metabolic diseases. Typical applications are pharmacodynamic markers, predictive markers to support precision medicine and monitoring markers to assess efficacy or to detect residual disease / recurrence. Extracellular vesicles are often highly compatible with liquid biopsy, lowering the burden requested to participating subjects regarding the biosamples.",
    "pharmas-and-biotechs.section_2.label": "Empowering and differentiating your clinical programs with innovative markers",
    "pharmas-and-biotechs.section_2.text_1": "",
    "pharmas-and-biotechs.section_3.label": "Practical example of partnership",
    "pharmas-and-biotechs.section_3.text_1": "The typical setting for a partnership is that we support exploratory endpoints of your clinical trials. We might receive frozen plasma from the participants and perform biomarker discovery and validation. You will receive a report with the complete set of data and analysis performed with state- of-the-art bioinformatics. In oncology, we generally work with 100 microliters of plasma. The final volume required will depend on the analyses requested, but generally speaking, the analysis of extracellular vesicles uses a limited amount of biofluid.",

    // ACADEMIA 
    "academia.document_title": "EV Biome | Academia",
    "academia.hero.title": "Academia",
    "academia.hero.subtitle": "",
  }
}
