import { FC, ReactElement, useEffect } from "react";
import PageHero from "../../../../components/PageHero";
import Reference from "../../../../components/Reference";
import SharedProps from "../../../../models/SharedProps";

interface PharmasAndBiotechsProps extends SharedProps {};

const PharmasAndBiotechs: FC<PharmasAndBiotechsProps> = (props: PharmasAndBiotechsProps): ReactElement => {
  const { translate, locale } = props;

  const t = (key: string): string => translate("pharmas-and-biotechs." + key);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = t("document_title");
  }, []);

  return (
    <>
      <PageHero
        title={t("hero.title")}
        imageUrl="https://evbiome-assets.s3.eu-central-1.amazonaws.com/hero__partnerships-pharma.jpg"
        textVariant="dark"
//        imageUrl="https://image.shutterstock.com/image-photo/two-hands-connect-puzzles-on-600w-1708939942.jpg"
//        imageUrl="https://image.shutterstock.com/image-photo/two-hands-connect-puzzles-on-600w-1701979165.jpg"
      />

      <div id="section_1" className="team__intro-container">
        <div className="team__intro app-margin">
          <div className="team__intro-text">
            <h3 style={{ marginTop: "2rem"}}>{t("section_1.label")}</h3>
            <h4 style={{ marginTop: "2rem"}}>{t("section_1.text_1")}</h4>
          </div>
        </div>
      </div>

      <div id="section_2" className="team__intro-container">
        <div className="team__intro app-margin">
          <div className="team__intro-text">
            <h3>{t("section_2.label")}</h3>
            <h4 style={{ marginTop: "2rem"}}>
Although other technologies such as circulating tumor DNA are available in the field of liquid biopsy, extracellular vesicles show some unique features that might allow your clinical trials to differentiate from competing compounds. In pancreas cancer for instance, one of the most sensitive and specific means to detect tumors is based on extracellular vesicles <Reference to="https://www.fdanews.com/articles/205023-fda-designates-biological-dynamics-pancreatic-cancer-test-a-breakthrough-device" />. In neurodegenerative disease, EVbiome has been first to detect disease-associated prions from the blood of patients with sporadic Creutzfeld-Jacob disease. If your translational budget allows it, you might want to explore innovative approaches such as extracellular vesicles that might add significant values to your clinical trials.
            </h4>
          </div>
        </div>
      </div>

      <div id="section_3" className="team__intro-container">
        <div className="team__intro app-margin">
          <div className="team__intro-text">
            <h3>{t("section_3.label")}</h3>
            <h4 style={{ marginTop: "2rem", marginBottom: "2rem"}}>{t("section_3.text_1")}</h4>
          </div>
        </div>
      </div>

    </>
  )
}

export default PharmasAndBiotechs;
