import { FC, ReactElement, useEffect } from "react";
import PageHero from "../../../../components/PageHero";
import SharedProps from "../../../../models/SharedProps";

interface CompanyProps extends SharedProps {};

const Company: FC<CompanyProps> = (props: CompanyProps): ReactElement => {
  const { translate, locale } = props;

  const t = (key: string): string => translate("company." + key);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = t("document_title");
  }, []);

  return (
    <>
      <PageHero
        title={t("hero.title")}
        subtitle={t("hero.subtitle")}
        imageUrl="https://evbiome-assets.s3.eu-central-1.amazonaws.com/hero__company.jpg"
//        imageUrl="https://thumbs.dreamstime.com/z/business-woman-hand-clock-gears-money-lamp-symbolizes-effective-implementation-ideas-85403633.jpg"
//        imageUrl="https://thumbs.dreamstime.com/b/logical-difficulties-confusion-thoughts-puzzles-above-head-wooden-figure-logical-difficulties-confusion-thoughts-159089928.jpg"
      />

      <div className="team__intro-container">
        <div className="team__intro app-margin">
          <div className="team__intro-text">
            <h4 style={{ marginTop: "2rem"}}>{t("vision")}</h4>
            <br/>
            <h4 style={{ marginBottom: "2rem"}}>{t("mission")}</h4>
          </div>
        </div>
      </div>
    </>
  )
}

export default Company;
