import { FC, ReactElement, useEffect } from "react";
import SharedProps from "../../../../models/SharedProps";

import "./styles.css";

interface PublicationsProps extends SharedProps {};

const Publications: FC<PublicationsProps> = (props: PublicationsProps): ReactElement => {
  const { translate, locale } = props;

  const t = (key: string): string => translate("publications." + key);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = t("document_title");
  }, []);

  return (
    <>
      <div className="team__hero-container">
        <div className="team__hero app-margin">
          <div className="team__hero-text">
            <h2>{t("hero.title")}</h2>
            <h4>{t("hero.subtitle")}</h4>
          </div>
        </div>
      </div>

      <div className="team__intro-container">
        <div className="team__intro app-margin">
          <div className="publications__section">
            <h3 style={{ marginTop: "2rem"}}>{t("publications.label")}</h3>
            <ul>
              <li>
                <a
                  href="https://bio-protocol.org/e3512"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link"
                >
                  <h5 className="link__title">Bioprotocol</h5>
                </a>
              </li>
              <li>
                <a
                  href="https://www.thelancet.com/journals/ebiom/article/PIIS2352-3964(19)30278-6/fulltext"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link"
                >
                  <h5 className="link__title">Ebiomedicine</h5>
                </a>
              </li>
              <li>
                <h5>Translational Oncology McNamee 2022</h5>
              </li>
            </ul>
          </div>

          <div className="publications__section">
            <h3 style={{ marginTop: "2rem"}}>{t("patents.label")}</h3>
            <ul>
              <li>
                <h5>NBI</h5>
              </li>
              <li>
                <h5>Provisional application GYPA-FCET</h5>
              </li>
            </ul>
          </div>

          <div className="publications__section">
            <h3 style={{ marginTop: "2rem"}}>{t("references.label")}</h3>
            <ul>
              <li>
                <h5>Reference 1</h5>
              </li>
              <li>
                <h5>Reference 2</h5>
              </li>
              <li>
                <h5>Reference 3</h5>
              </li>
            </ul>
          </div>
 
        </div>
      </div>
    </>
  )
}

export default Publications;
