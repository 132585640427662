import { FC, ReactElement, useEffect } from "react";
import SharedProps from "../../models/SharedProps";
import history from "../../utils/history";
import range from "lodash/range";

interface NewsProps extends SharedProps {};

const News: FC<NewsProps> = (props: NewsProps): ReactElement => {
  const { translate, locale } = props;

  const t = (key: string): string => translate("news." + key);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = t("document_title");
  }, []);

  return (
    <>
      <div className="team__hero-container">
        <div className="team__hero app-margin">
          <div className="team__hero-text">
            <h2>{t("hero.title")}</h2>
            <h4>{t("hero.subtitle")}</h4>
          </div>
        </div>
      </div>

       <div className="team__intro-container">
        <div className="team__intro app-margin">

          <div className="publications__section">
            <h3 style={{ marginTop: "2rem"}}>UPCOMING</h3>
            <ul style={{ listStyleType: "none"}}>
                <a
                  href=""
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link"
                >
                                <li style={{marginBottom: "26.72px"}} >
                  <h5 className="link__title" style={{margin: 0, fontStyle: "italic"}}>Event Name</h5>

                <br />
                <small><strong>Location | </strong> March 07, 2023 - March 09, 2023</small>
                <br/>
                <br/>
                <small>Event description: Aliquam temporibus assumenda iusto. Ducimus quia omnis fugiat consequatur quae sit. Numquam consequatur odit repellendus ex. Corporis quis laborum voluptas labore veritatis incidunt sequi. Voluptas aperiam sunt quis dolor quidem sunt qui sed. Et saepe magni eveniet vitae optio exercitationem...</small>
              </li>
                </a>
            </ul>
          </div>
 
          <div className="publications__section">
            <h3 style={{ marginTop: "2rem"}}>2022</h3>
            <ul style={{ listStyleType: "none"}}>
                <a
                  href=""
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link"
                >
 
                <li style={{marginBottom: "26.72px"}} >
                 <h5 className="link__title" style={{margin: 0, fontStyle: "italic"}}>News Article Title</h5> 

                <br />
                <small><strong>Source | </strong> June 07, 2022</small>
                <br/>
                <br/>
                <small>Article preview snippet: Aliquam temporibus assumenda iusto. Ducimus quia omnis fugiat consequatur quae sit. Numquam consequatur odit repellendus ex. Corporis quis laborum voluptas labore veritatis incidunt sequi. Voluptas aperiam sunt quis dolor quidem sunt qui sed. Et saepe magni eveniet vitae optio exercitationem...</small>
                <br/>
                <br/> 

              </li>
                </a>


                <a
                  href=""
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link"
                >
                                <li style={{marginBottom: "26.72px"}}>
                  <h5 className="link__title" style={{margin: 0, fontStyle: "italic"}}>Event Name</h5> 
                <br />
                <small><strong>Location | </strong> March 02, 2022</small>
                <br/>
                <br/>
                <small>Event description: Aliquam temporibus assumenda iusto. Ducimus quia omnis fugiat consequatur quae sit. Numquam consequatur odit repellendus ex. Corporis quis laborum voluptas labore veritatis incidunt sequi. Voluptas aperiam sunt quis dolor quidem sunt qui sed. Et saepe magni eveniet vitae optio exercitationem...</small>
              </li>
                </a>



            </ul>
          </div>

          <div className="publications__section">
            <h3 style={{ marginTop: "2rem"}}>2021</h3>
            <ul style={{ listStyleType: "none"}}>

                <a
                  href=""
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link"
                >
                                <li style={{marginBottom: "26.72px"}} >
                  <h5 className="link__title" style={{margin: 0, fontStyle: "italic"}}>News Article Title</h5> 
                <br />
                <small><strong>Source | </strong> December 20, 2021</small>
                <br/>
                <br/>
                <small>Article preview snippet: Aliquam temporibus assumenda iusto. Ducimus quia omnis fugiat consequatur quae sit. Numquam consequatur odit repellendus ex. Corporis quis laborum voluptas labore veritatis incidunt sequi. Voluptas aperiam sunt quis dolor quidem sunt qui sed. Et saepe magni eveniet vitae optio exercitationem...</small>

              </li>
    </a>
            </ul>
          </div>
 
        </div> 

      </div>
    </>
  )
}

export default News;
