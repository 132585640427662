import React, { FC, ReactElement } from "react";

type ReferenceProps = {
  to?: string;
  text?: string;
}

const Reference: FC<ReferenceProps> = (props: ReferenceProps): ReactElement => {
  const { to, text } = props;

  return (
    <>
      <span>[</span>
      <a
        href={to}
        style={{ pointerEvents: to ? "all" : "none" }}
        target="_blank"
        rel="noopener noreferrer"
        className="ref"
      >
        Ref
        {!!text && (
          <>
            <span style={{ fontWeight: 300 }}>{": "}</span>
            <span style={{ fontWeight: 300, fontStyle: "italic" }}>{text}</span>
          </>
        )}
      </a>
      <span>]</span>
    </>
  )
}

export default Reference;
