export const CONTACT_PHONE = "";

export const CONTACT_EMAIL = "info@evbiome.com";

export const CONTACT_ADDRESS_L1 = "EVBiome";
export const CONTACT_ADDRESS_L2 = "1234 Temp Avenue";
export const CONTACT_ADDRESS_L3 = "99999 City"
export const CONTACT_ADDRESS_L4 = "COUNTRY";

export const CONTACT_LINKED_IN = "https://www.linkedin.com/company/evbiome";
