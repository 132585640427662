import classNames from "classnames";
import range from "lodash/range";
import React, { FC, ReactElement } from "react";

import { getColorGradient } from "../../utils/colors";
import history from "../../utils/history";

import "./styles.css";

interface GradientBoxContainerProps {
  children: JSX.Element[];
}

const GradientBoxContainer: FC<GradientBoxContainerProps> = (
  props: GradientBoxContainerProps
): ReactElement => {
  const { children = []} = props;

//  const blue = { r: 45, g: 161, b: 180 };
//  const blue = { r: 34, g: 131, b: 148 };

  const bgcolor = {
    r: 72,
    g: 61,
    b: 139
  }

  const rgb2 = { r: 255, g: 255, b: 255 };
  const percentages = [0.1, 0.2, 0.3, 0];

  return (
    <>
      <div className="gradient-box">
        {/*range(2).map((index) => (
          <div
            key={["gradient-box", index].join("-")}
            className={classNames("gradient-box__box", `gradient-box__${index}`)}
            style={{ background: `${getColorGradient(blue, rgb2, percentages[index], "string")}` }}
          >
            {children[index] || <></>}
          </div>
        ))*/}
        {children[2] && (
          <div
            key={["gradient-box", 3].join("-")}
            className={classNames("gradient-box__box-full", `gradient-box__3`)}
            style={{ background: `${getColorGradient(bgcolor, rgb2, percentages[3], "string")}` }}
          >
            {children[2]}
          </div>
        )}
      </div>
      <div
        className={classNames("app-footer__home")}
        onClick={() => history.push("/")}
        style={{ background: `${getColorGradient(bgcolor, rgb2, percentages[3], "string")}` }}
      >
        <i className="fa fa-home fa-2x pointer" aria-hidden="true" />
      </div>
      <div
        className={classNames("app-footer__copyright")}
        style={{ background: `${getColorGradient(bgcolor, rgb2, percentages[3], "string")}` }}
      >
        <p className="m0 mt30 text-small">© 2022 EVBiome</p>
      </div>
    </>
  )
}

export default GradientBoxContainer;
