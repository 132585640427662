import classNames from "classnames";
import React, { FC, ReactElement } from "react";
import { AnyAction, Dispatch } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import GradientBoxContainer from "../../components/GradientBox";

import SharedProps from "../../models/SharedProps";
import { Locale } from "../../models/Translations";

import { setLocale } from "../../redux/app/actions";
import { selectCurrentPath, selectLocale } from "../../redux/app/selectors";

import history from "../../utils/history";
import {
  CONTACT_ADDRESS_L1,
  CONTACT_ADDRESS_L2,
  CONTACT_ADDRESS_L3,
  CONTACT_ADDRESS_L4,
  CONTACT_EMAIL,
  CONTACT_LINKED_IN
} from "../../utils/contact";

// styles
import "./styles.css";

interface AppFooterProps extends SharedProps {
  locale: Locale;
  setLocale: (locale: Locale) => AnyAction;
  currentPath: string;
}

const AppFooter: FC<any> = (props: AppFooterProps): ReactElement => {
  const { locale, setLocale, currentPath, translate } = props;

  const t = (key: string): string => translate("footer." + key);

  return (
    <div className={classNames("app__footer", "app-footer", { hidden: currentPath === "/menu" })}>
      <GradientBoxContainer>
        <div className="app-footer__box gradient-box__inner">
          <i className="fa fa-map-marker" aria-hidden="true" />
          <div>
            <p style={{ lineHeight: "1.8"}} className="app-footer__label m0">{t("address")}</p>
            <p className="m0">{CONTACT_ADDRESS_L1}</p>
            <p className="m0">{CONTACT_ADDRESS_L2}</p>
            <p className="m0">{CONTACT_ADDRESS_L3}</p>
            <p className="m0">{CONTACT_ADDRESS_L4}</p>
          </div>
        </div>

        <div className="gradient-box__inner">
          <div className="app-footer__box">
            <i className="fa fa-envelope" aria-hidden="true" />
            <div>
              <p style={{ lineHeight: "1.7" }} className="app-footer__label m0">{t("email")}</p>
              <a className="link--dark" href={`mailto:${CONTACT_EMAIL}`}> {/*TODO: replace mailto with contact form*/}
                <p className="m0">{CONTACT_EMAIL}</p>
            </a>
          </div>
        </div>
      </div>

      <div className="app-footer__nav gradient-box__inner">
        <div>
          <p className="app-footer__label m0">{t("nav.about.label")}</p>
          <p className="m0 pointer" onClick={() => history.push("/company")}>{t("nav.company.label")}</p>
          <p className="m0 pointer" onClick={() => history.push("/team")}>{t("nav.team.label")}</p>
          {/*<p className="m0 pointer" onClick={() => history.push("/news")}>{t("nav.news.label")}</p>*/}
        </div>
        <div>
          <p className="app-footer__label m0">{t("nav.programs.label")}</p>
          <p className="m0 pointer" onClick={() => history.push("/diagnostics")}>{t("nav.diagnostics.label")}</p>
          <p className="m0 pointer" onClick={() => history.push("/therapeutics")}>{t("nav.therapeutics.label")}</p>
        </div>
        <div>
          <p className="app-footer__label m0">{t("nav.partnerships.label")}</p>
          <p className="m0 pointer" onClick={() => history.push("/pharmas-and-biotechs")}>{t("nav.pharmas-and-biotechs.label")}</p>
          <p className="m0 pointer" onClick={() => history.push("/academia")}>{t("nav.academia.label")}</p>
        </div>
        <div>
          <p className="app-footer__label m0">{t("nav.technology.label")}</p>
          <p className="m0 pointer" onClick={() => history.push("/nbi")}>{t("nav.nbi.label")}</p>
          {/*<p className="m0 pointer" onClick={() => history.push("/publications")}>{t("nav.publications.label")}</p>*/}
          {/*<p className="m0 pointer" onClick={() => history.push("/point-of-care")}>{t("nav.point-of-care.label")}</p>*/}
        </div>
      </div>
    </GradientBoxContainer>
  </div>
  )
}

const mapStateToProps = createStructuredSelector({
  locale: selectLocale,
  currentPath: selectCurrentPath
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    setLocale: (locale: Locale): AnyAction => dispatch(setLocale(locale))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppFooter);
