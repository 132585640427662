import { FC, ReactElement, useEffect } from "react";
import PageHero from "../../../../components/PageHero";
import Reference from "../../../../components/Reference";
import SharedProps from "../../../../models/SharedProps";

interface TherapeuticsProps extends SharedProps {};

const Therapeutics: FC<TherapeuticsProps> = (props: TherapeuticsProps): ReactElement => {
  const { translate, locale } = props;

  const t = (key: string): string => translate("therapeutics." + key);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = t("document_title");
  }, []);

  return (
    <>
      <PageHero
        title={t("hero.title")}
        subtitle={t("hero.subtitle")}
        imageUrl="https://evbiome-assets.s3.eu-central-1.amazonaws.com/hero__therapeutics.jpg"
//imageUrl="https://image.shutterstock.com/image-photo/blue-sky-white-clouds-600w-1036234570.jpg"
//imageUrl="https://thumbs.dreamstime.com/z/blue-beautiful-sky-white-clouds-sunny-day-12747379.jpg"
        //imageUrl="https://image.shutterstock.com/image-photo/clouds-blue-sky-background-copy-600w-1341718424.jpg"
//        imageUrl="https://image.shutterstock.com/image-photo/natural-blue-sky-cloud-cumulus-600w-1835269792.jpg"
//        imageUrl="https://image.shutterstock.com/image-photo/microscope-lab-glassware-science-laboratory-600w-531253342.jpg"
//        imageUrl="https://thumbs.dreamstime.com/z/therapeutic-dose-drug-treatment-concept-opened-medical-ampoule-sterile-syringe-injection-medicines-vaccine-154944417.jpg"
      />

      <div className="team__intro-container">
        <div className="team__intro app-margin">
          <div className="team__intro-text" style={{ margin: "2rem 0"}}>
            <h4>{t("text_1")}</h4>
            <br/>
            <h4>{t("text_2")}</h4>
            <br/>
            <h4>{t("text_3")}</h4>
            <br/>
            <h4 style={{ marginBottom: "2rem" }}>
Example: Clinical grade MSC derived exosomes with KrasG12DSiRNA to treat pancreatic cancer (PDAC) in mice. <Reference text="Mendt et Al, JCI insight, (2018), 99263, 3(8))" />. Here, the iExosomes target oncogenic Kras to suppress patient derived xenograft:
            </h4>
            <div className="team__intro-image" style={{ width: "80%", maxWidth: "640px", margin: "auto" }}>
              <img
                style={{ width: "-webkit-fill-available" }}
                src="https://everimmune-public-assets.s3.eu-central-1.amazonaws.com/image003.png"
                alt="therapeutics-fig-1"
              />
            </div>
            <h4 style={{ marginTop: "2rem" }}>
Finally, exosomes have also been tested as actual drug delivery vehicles, to efficiently deliver drugs inside the target cells. For example, in Development of Exosome-encapsulated Paclitaxel to Overcome MDR in Cancer cells <Reference text="Kim et Al, Nanomedicine (2016), 655-664, 12 (3)" />, macrophage exosomes were isolated and ‘loaded’ with Paclitaxel, a potent chemotherapeutic agent in a mouse model of multidrug resistant lung carcinoma metastases. The efficient delivery and the observed tissue of origin cell tropism.
            </h4>
            <br/>
            <h4>{t("text_6")}</h4>
            <br/>
            <h4>{t("text_7")}</h4>
          </div>
        </div>
      </div>
    </>
  )
}

export default Therapeutics;
