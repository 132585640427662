import { FC, ReactElement } from "react";
import { Route, Switch, withRouter } from "react-router";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { Locale } from '../../models/Translations';
import { selectLocale } from "../../redux/app/selectors";
import MAIN_ROUTES from "../../routes";
import Home from "../../views/Home";
import AppHeader from "../../views/AppHeader";
import AppFooter from '../../views/AppFooter';
import translate from '../../utils/translations';
import translations from "../../translations/main";

interface AppProps {
  locale: Locale;
}

const App: FC<any> = (props: AppProps): ReactElement => {
  const { locale } = props;

  window.onscroll = function(e) {
    const element = document.getElementById("app-header");
    if (window.pageYOffset === 0) {
      element?.classList.remove("app__header--sticky");
    } else {
      element?.classList.add("app__header--sticky");
    }
  };

  const getAllRoutes = () => {
    let allRoutes: any[] = [];

    MAIN_ROUTES.forEach(route => {
      allRoutes = [...allRoutes, route, ...(route.subroutes || [])];
    });

    return allRoutes.filter(({ isDisabled }) => !isDisabled);
  }

  const translationWrapper = (key: string): string => {
    return translate(translations, locale, key);
  };

  const sharedProps = { translate: translationWrapper, locale };

  return (
    <main className="app">
      <AppHeader {...sharedProps} />
      <div className="app__container">
        <div className="app__body">
          <Switch>
            {getAllRoutes().map((route) => (
              <Route exact={true} key={route.id} {...route} component={() => <route.component {...sharedProps} />} />
            ))}
            <Route path="*" component={() => <Home {...sharedProps} />} />
          </Switch>
        </div>
        <AppFooter {...sharedProps} />
      </div>
    </main>
  );
}

const mapStateToProps = createStructuredSelector({
  locale: selectLocale
});

const connectedApp = connect(mapStateToProps, null)(App);

export default withRouter(connectedApp);
