import classNames from "classnames";
import React, { FC, ReactElement, ReactNode } from "react";

import "./styles.css";

interface PageHeroProps {
  title?: string;
  subtitle?: string;
  imageUrl: string;
  children?: ReactNode;
  textVariant?: "light" | "dark"
}

const PageHero: FC<PageHeroProps> = (props: PageHeroProps): ReactElement => {
  const { title, subtitle, imageUrl, children = <></>, textVariant = "light" } = props;

  const background = { backgroundImage: `url("${imageUrl}")` };

  return (
      <div className="page-hero__background" style={background}>
        <div className="page-hero__text-container app-margin">
          <div className={classNames("page-hero__text", { "page-hero__text--dark": textVariant === "dark" })}>
            {!!title && <h1>{title}</h1>}
            {/*!!subtitle && <h3>{subtitle}</h3>*/}
            {children}
          </div>
        </div>
      </div>
  )
}

export default PageHero;
