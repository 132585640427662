import { FC, ReactElement, useEffect } from "react";
import SharedProps from "../../models/SharedProps";
import history from "../../utils/history";

import "./styles.css";

interface HomeProps extends SharedProps {};

const Home: FC<HomeProps> = (props: HomeProps): ReactElement => {
  const { translate, locale } = props;

  const t = (key: string): string => translate("home." + key);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = t("document_title");
  }, []);

  return (
    <>
      <div className="home__hero-container">
        <div className="home__hero app-margin">
          <div className="home__hero-text">
            <h1>EVBiome</h1>
            <h3>Scientific innovation for better health</h3>
          </div>
        </div>
      </div>

      <div className="home__mission-container">
        <div className="home__mission app-margin">
          <div className="home__mission-text">
            <h2>{t("mission.label")}</h2>
            <h4>{t("mission.text")}</h4>
          </div>
          <div className="home__mission-button">
            <button onClick={() => history.push("/team")}>
              {t("mission.button.label")}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Home;
