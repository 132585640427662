import { FC, ReactElement, useEffect } from "react";
import SharedProps from "../../../../models/SharedProps";

interface PointOfCareProps extends SharedProps {};

const PointOfCare: FC<PointOfCareProps> = (props: PointOfCareProps): ReactElement => {
  const { translate, locale } = props;

  const t = (key: string): string => translate("point-of-care." + key);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = t("document_title");
  }, []);

  return (
    <>
      <div className="team__hero-container">
        <div className="team__hero app-margin">
          <div className="team__hero-text">
            <h2>{t("hero.title")}</h2>
            <h4>{t("hero.subtitle")}</h4>
          </div>
        </div>
      </div>

      <div className="team__intro-container">
        <div className="team__intro app-margin">
          <div className="publications__section">
            <h3 style={{ marginTop: "2rem"}}>{t("ongoing.label")}</h3>
              <ul>
              <li>
                  <h5 className="link__title" style={{margin: 0, fontStyle: "italic"}}>Protocol Optimization</h5> 
                <br />
                <h5>Better robustness and transferability</h5>
                <br/>
                <br/> 
              </li>

              <li>
                  <h5 className="link__title" style={{margin: 0, fontStyle: "italic"}}>Reducing Interfering Factors</h5> 
                <br />
                <h5>ex: Albumin depletion</h5>
                <br/>
                <br/> 
              </li>

               <li>
                  <h5 className="link__title" style={{margin: 0, fontStyle: "italic"}}>Adapting our Platform to Enclosed Microfluidic Chip</h5> 
                <br />
                <h5>Project description</h5>
                <br/>
                <small>Partners:</small>
                <div style={{ paddingTop: "8px"}}>
                <img 
                  src="https://flanders.bio/storage/files/49be3bd5-3fe4-41b7-87a4-df4d97b04b1f/company_logo-imec-blacksvg.png"
                  height={100}
                  width={200}
                  alt="imec-logo"
                />
                </div>
                <br/>
                <br/> 
              </li>
 
           </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default PointOfCare;
