import emailjs from 'emailjs-com';
import { FC, ReactElement, useEffect, useState } from "react";
import PageHero from "../../components/PageHero";
import SharedProps from "../../models/SharedProps";
import "./styles.css";

interface ContactProps extends SharedProps {};

const Contact: FC<ContactProps> = (props: ContactProps): ReactElement => {
  const { translate, locale } = props;

  const t = (key: string): string => translate("contact." + key);

  const [formStatus, setFormStatus] = useState<"error" | "success" | undefined>();

  useEffect(() => {
    setTimeout(() => {
      setFormStatus(undefined);
    }, 8000);
  }, [formStatus]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = t("document_title");
  }, []);

  const clearForm = (): void => {
    const form = document.getElementById("header-contact-form");
    if (form) (form as any).reset();
  }

  const sendEmail = (e: any) => {
    e.preventDefault();
//    status.setValue(BASIC_LOADING_STATE);
 
    emailjs.sendForm('service_g3c3v4l', 'template_s4ckcij', e.target, 'user_ovLKnLxrbBP1aTtoWys2j')
      .then((result) => {
        setFormStatus("success");
        clearForm();
      }, (error) => {
        setFormStatus("error");
//        status.setValue({...BASIC_ERROR_STATE, payload: error.text});
      });
  }

  return (
    <>
      <PageHero
        title={t("hero.title")}
        imageUrl="https://evbiome-assets.s3.eu-central-1.amazonaws.com/hero__contact.jpg"
      />

      <form onSubmit={sendEmail} id="header-contact-form">
        <input type="hidden" name="contact_number" />

        <div className="contact-form">
          <div className="contact-form__row-container">
          <div className="contact-form__row">
            <div className="form-element">
            <label
              className="input-label"
              htmlFor="form-name"
            >
              {t("form.name.label")}
            </label>
            <input
              type="text"
              id="form-name"
              className="form-control"
              placeholder={t("form.name.placeholder")}
              name="user_name"
            />
          </div>

          <div className="form-element">
            <label
              className="input-label"
              htmlFor="form-email"
            >
              {t("form.email.label")}
            </label>
 
          <input
            type="email"
            id="form-email"
            className="form-control"
            name="user_email"
            placeholder={t("form.email.placeholder")}
          />
          </div>
        </div>
        </div>
        <div className="contact-form__row-container">
        <div className="contact-form__row"> 
          <div className="form-element">
            <label
              className="input-label"
              htmlFor="form-subject"
            >
              {t("form.subject.label")}
            </label>
 
 
        <input
          type="text"
          id="form-subject"
          className="form-control"
          name="subject"
          placeholder={t("form.subject.placeholder")}
        />
        </div>
        </div>
        </div>
        <div className="contact-form__row-container">
        <div className="contact-form__row">
          <div className="form-element">
            <div className="textarea-label">
            <label
              className="input-label"
              htmlFor="form-message"
            >
              {t("form.message.label")}
            </label>
            </div>
        <textarea
          id="form-message"
          className="form-control"
          name="message"
          placeholder={t("form.message.placeholder")}
        />
        </div>
        </div>
        </div>
        {formStatus === "success" && (
          <p style={{ margin: "12px", color: "limegreen", textAlign: "center" }}>Thank you! Your message was sent successfully.</p>
        )}
        {formStatus === "error" && (
          <p style={{ margin: "12px", color: "red", textAlign: "center" }}>An error occurred and your message could not be sent.</p>
        )}
        <div className="contact-form__row-container">
        <div className="contact-form__row contact-form__row--submit">
          <button className="contact__submit-button" type="submit">
            {t("form.button.label")}
          </button>
        </div>
        </div> 
      </div>
      </form>
    </>
  );
}

export default Contact;
