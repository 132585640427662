import { FC, ReactElement, useEffect, useState } from "react";
import PageHero from "../../../../components/PageHero";
import SharedProps from "../../../../models/SharedProps";

import "./styles.css";

interface TeamProps extends SharedProps {};

const Team: FC<TeamProps> = (props: TeamProps): ReactElement => {
  const { translate, locale } = props;

  const t = (key: string): string => translate("team." + key);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = t("document_title");
  }, []);

  return (
    <>
      <PageHero
        title={t("hero.title")}
        subtitle={t("hero.subtitle")}
        imageUrl="https://evbiome-assets.s3.eu-central-1.amazonaws.com/hero__team.jpg"
      />

      <div className="team__intro-container">
        <div className="team__intro app-margin">
          <div className="team__intro-text">
            <h4>{t("intro_text")}</h4>
          </div>
        </div>
      </div>

      <div className="team__people-container">
        <div className="team__people app-margin">
          <div className="team__grid">
            <div className="team__grid-item">
              <img
                height={300*0.75}
                width={250*0.75}
                src="https://evbiome-assets.s3.eu-central-1.amazonaws.com/HIROAKI-TANAKA.jpg"
                alt="Hiroaki Tanaka"
              />
              <div className="team__grid-item-details">
                <div className="nametag">
                  <p className="name">
                    Hiroaki Tanaka
                  </p>
                  <p className="title">
                    CEO
                  </p>
                </div>
                <a
                  href="https://www.linkedin.com/in/hiroaki-tanaka/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-linkedin " />
                </a>
              </div>
            </div>

            <div className="team__grid-item">
              <img
                height={300*0.75}
                width={250*0.75}
                src="https://evbiome-assets.s3.eu-central-1.amazonaws.com/HADI-ABDERRAHIM.jpg"
                alt="Hadi Abderrahim"
              />
              <div className="team__grid-item-details">
                <div className="nametag">
                  <p className="name">
                    Hadi Abderrahim
                  </p>
                  <p className="title">
                    Cofounder
                  </p>
                </div>
                <a
                  href="https://www.linkedin.com/in/hadiabderrahimmd/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-linkedin " />
                </a>
              </div> 
            </div>

            <div className="team__grid-item">
              <img
                height={300*0.75}
                width={250*0.75}
                src="https://evbiome-assets.s3.eu-central-1.amazonaws.com/ELIO-RIBOLI.PNG"
                alt="Elio Riboli"
              />
              <div className="team__grid-item-details">
                <div className="nametag">
                  <p className="name">
                    Elio Riboli
                  </p>
                  <p className="title">
                    CSO
                  </p>
                </div>
                <a
                  href="https://www.linkedin.com/in/riboli-elio-3107785a/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-linkedin " />
                </a>
              </div> 
            </div>

            <div className="team__grid-item">
              <img
                height={300*0.75}
                width={250*0.75}
                src="https://evbiome-assets.s3.eu-central-1.amazonaws.com/BLANDINE-MERINO.png"
                alt="Blandine Merino"
              />
              <div className="team__grid-item-details">
                <div className="nametag">
                  <p className="name">
                    Blandine Merino
                  </p>
                  <p className="title">
                    Cofounder
                  </p>
                </div>
                <a
                  href="https://www.linkedin.com/in/blandinemerino/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-linkedin " />
                </a>
              </div> 
            </div>

            <div className="team__grid-item">
              <img
                height={300*0.75}
                width={168}
                src="https://evbiome-assets.s3.eu-central-1.amazonaws.com/jerome.jpg"
                alt="Jérôme Wojcik"
              />
              <div className="team__grid-item-details">
                <div className="nametag">
                  <p className="name">
                    Jérôme Wojcik
                  </p>
                  <p className="title">
                    Chief Data Officer
                  </p>
                </div>
                <a
                  href="https://www.linkedin.com/in/jeromewojcik/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-linkedin " />
                </a>
              </div> 
            </div>

          </div>
        </div>
      </div>
   </>


  )
}

export default Team;
