import { FC, ReactElement, useEffect } from "react";
import PageHero from "../../../../components/PageHero";
import SharedProps from "../../../../models/SharedProps";
import history from "../../../../utils/history";

interface AcademiaProps extends SharedProps {};

const Academia: FC<AcademiaProps> = (props: AcademiaProps): ReactElement => {
  const { translate, locale } = props;

  const t = (key: string): string => translate("academia." + key);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = t("document_title");
  }, []);

  return (
    <>
      <PageHero
        title={t("hero.title")}
        imageUrl="https://evbiome-assets.s3.eu-central-1.amazonaws.com/hero__academia.jpg"
//        imageUrl="https://image.shutterstock.com/image-photo/love-apple-600w-151518215.jpg"
//        imageUrl="https://thumbs.dreamstime.com/z/sharing-1589423.jpg"
      />

      <div className="team__intro-container">
        <div className="team__intro app-margin">
          <div className="team__intro-text" style={{ margin: "2rem 0"}}>
            <h4>
              EVBiome is open to share its NBI expertise with academic collaborators. Please <a onClick={() => history.push("/contact")} className="internal-link">contact us</a> for more information.
            </h4>
          </div>
        </div>
      </div>
    </>
  )
}

export default Academia;
